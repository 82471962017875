"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// index.ts
var schema_exports = {};
__export(schema_exports, {
  ActivityFeedContentType: () => ActivityFeedContentType,
  ActivityFeedSearchSortOrder: () => ActivityFeedSearchSortOrder,
  ActivityFeelings: () => ActivityFeelings,
  AddressType: () => AddressType,
  AddressUse: () => AddressUse,
  AiConversationSearchSortOrder: () => AiConversationSearchSortOrder,
  AiMessageSearchSortOrder: () => AiMessageSearchSortOrder,
  AiSettingType: () => AiSettingType,
  AppointmentTypes: () => AppointmentTypes,
  AreaOfConcernType: () => AreaOfConcernType,
  AreasOfLife: () => AreasOfLife,
  AreasOfLifeHabitImpact: () => AreasOfLifeHabitImpact,
  BirthSex: () => BirthSex,
  CalendarEventStatuses: () => CalendarEventStatuses,
  CalendarEventTypes: () => CalendarEventTypes,
  CheckinType: () => CheckinType,
  CommunityGroupVisibility: () => CommunityGroupVisibility,
  CommunityStatus: () => CommunityStatus,
  CommunityUserAvatarBackgroundColor: () => CommunityUserAvatarBackgroundColor,
  CommunityUserAvatarSvgIcon: () => CommunityUserAvatarSvgIcon,
  CommunityUserAvatarType: () => CommunityUserAvatarType,
  ConversationReviewStatus: () => ConversationReviewStatus,
  DoesUseSubstanceMoreThanUsuallyFrequencyPattern: () => DoesUseSubstanceMoreThanUsuallyFrequencyPattern,
  DownloadEbookSupportedCondition: () => DownloadEbookSupportedCondition,
  DrawbackOrConcernCategory: () => DrawbackOrConcernCategory,
  EducationLevel: () => EducationLevel,
  EmailType: () => EmailType,
  EmojiReaction: () => EmojiReaction,
  EmploymentDurationType: () => EmploymentDurationType,
  EmploymentStatus: () => EmploymentStatus,
  EncounterNotesAiStatus: () => EncounterNotesAiStatus,
  EncounterNotesClinicianStatus: () => EncounterNotesClinicianStatus,
  EncounterNotesReasonForMissedAppointment: () => EncounterNotesReasonForMissedAppointment,
  EncounterNotesSearchSortOrder: () => EncounterNotesSearchSortOrder,
  EncounterNotesStatus: () => EncounterNotesStatus,
  EncounterNotesSupervisorStatus: () => EncounterNotesSupervisorStatus,
  EntryType: () => EntryType,
  FamilyRelationshipType: () => FamilyRelationshipType,
  FeelingsAfterTechnique: () => FeelingsAfterTechnique,
  FileAttachmentStatus: () => FileAttachmentStatus,
  FilesSearchSortOrder: () => FilesSearchSortOrder,
  ForYouCardType: () => ForYouCardType,
  FormStatus: () => FormStatus,
  FriendStatus: () => FriendStatus,
  GenderIdentity: () => GenderIdentity,
  HabitAndSubstanceList: () => HabitAndSubstanceList,
  HabitBenefitAssessment: () => HabitBenefitAssessment,
  HabitCategory: () => HabitCategory,
  HabitOrSubstanceUseFrequencyPattern: () => HabitOrSubstanceUseFrequencyPattern,
  HabitOrSubstanceUseTimeOfDay: () => HabitOrSubstanceUseTimeOfDay,
  HabitsList: () => HabitsList,
  HowMuchConcern: () => HowMuchConcern,
  HowMuchTimeToInvestToBetterManageUnwantedHabits: () => HowMuchTimeToInvestToBetterManageUnwantedHabits,
  IntakeStatus: () => IntakeStatus,
  InterferenceFrequencyInDailyLife: () => InterferenceFrequencyInDailyLife,
  InviteStatus: () => InviteStatus,
  InvitedToDownloadMethod: () => InvitedToDownloadMethod,
  InvitedToDownloadStatus: () => InvitedToDownloadStatus,
  LifeExperienceCategory: () => LifeExperienceCategory,
  MaritalStatus: () => MaritalStatus,
  MediaMessageType: () => MediaMessageType,
  MilitaryBranch: () => MilitaryBranch,
  Mood: () => Mood,
  NoteContentType: () => NoteContentType,
  NoteStatus: () => NoteStatus,
  NoteType: () => NoteType,
  PastLifeExperienceEvents: () => PastLifeExperienceEvents,
  PastOrRecentLifeExperienceCategory: () => PastOrRecentLifeExperienceCategory,
  PostInteractionType: () => PostInteractionType,
  PostMediaAttachmentType: () => PostMediaAttachmentType,
  PostStatus: () => PostStatus,
  PreferenceTimeOfDay: () => PreferenceTimeOfDay,
  ProblemOrConcernCategory: () => ProblemOrConcernCategory,
  Pronouns: () => Pronouns,
  ProspectStatus: () => ProspectStatus,
  Race: () => Race,
  ReasonsOrBenefitsForUsingCategory: () => ReasonsOrBenefitsForUsingCategory,
  RecentLifeExperienceEvents: () => RecentLifeExperienceEvents,
  ReferralSourceSearchSortOrder: () => ReferralSourceSearchSortOrder,
  ReferralSourceType: () => ReferralSourceType,
  ReservationTypes: () => ReservationTypes,
  SectionStatus: () => SectionStatus,
  SelectRelatedProblemCategory: () => SelectRelatedProblemCategory,
  SessionStatus: () => SessionStatus,
  SexualOrientation: () => SexualOrientation,
  StateOfChange: () => StateOfChange,
  StrategyType: () => StrategyType,
  SubstanceList: () => SubstanceList,
  SupportTeamMemberRole: () => SupportTeamMemberRole,
  SupportTeamMemberTimingDayOfWeek: () => SupportTeamMemberTimingDayOfWeek,
  SupportTeamMemberTimingPeriod: () => SupportTeamMemberTimingPeriod,
  TaskPriority: () => TaskPriority,
  TaskSearchSortOrder: () => TaskSearchSortOrder,
  TaskStatus: () => TaskStatus,
  ThemeSeason: () => ThemeSeason,
  ThemeTimeOfDay: () => ThemeTimeOfDay,
  ThoughtRecordHabitEffect: () => ThoughtRecordHabitEffect,
  TimeOfDay: () => TimeOfDay,
  UsStates: () => UsStates,
  UserFormType: () => UserFormType,
  UserFormUse: () => UserFormUse,
  UserGroupSearchSortOrder: () => UserGroupSearchSortOrder,
  UserGroupStatus: () => UserGroupStatus,
  UserLearnMoreInterestedIn: () => UserLearnMoreInterestedIn,
  UserOnlineStatus: () => UserOnlineStatus,
  UserReferralChecklistType: () => UserReferralChecklistType,
  UserRoleSearchSortOrder: () => UserRoleSearchSortOrder,
  UserRoleStatus: () => UserRoleStatus,
  UserRoleType: () => UserRoleType,
  UserSearchSortOrder: () => UserSearchSortOrder,
  UserStatus: () => UserStatus,
  UserType: () => UserType,
  VideoCollectionNotificationTimePreference: () => VideoCollectionNotificationTimePreference,
  VideoCollectionSearchSortOrder: () => VideoCollectionSearchSortOrder,
  VideoCollectionStatus: () => VideoCollectionStatus,
  VideoMediaType: () => VideoMediaType,
  VideoSearchSortOrder: () => VideoSearchSortOrder,
  VideoStatus: () => VideoStatus
});
module.exports = __toCommonJS(schema_exports);

// src/generated/graphql.ts
var AiSettingType = /* @__PURE__ */ ((AiSettingType2) => {
  AiSettingType2["Companion"] = "COMPANION";
  AiSettingType2["EncounterNotes"] = "ENCOUNTER_NOTES";
  AiSettingType2["ReminderEmail"] = "REMINDER_EMAIL";
  return AiSettingType2;
})(AiSettingType || {});
var ActivityFeedContentType = /* @__PURE__ */ ((ActivityFeedContentType2) => {
  ActivityFeedContentType2["Markdown"] = "MARKDOWN";
  ActivityFeedContentType2["Text"] = "TEXT";
  return ActivityFeedContentType2;
})(ActivityFeedContentType || {});
var ActivityFeedSearchSortOrder = /* @__PURE__ */ ((ActivityFeedSearchSortOrder2) => {
  ActivityFeedSearchSortOrder2["CreatedAsc"] = "CREATED_ASC";
  ActivityFeedSearchSortOrder2["CreatedDesc"] = "CREATED_DESC";
  return ActivityFeedSearchSortOrder2;
})(ActivityFeedSearchSortOrder || {});
var ActivityFeelings = /* @__PURE__ */ ((ActivityFeelings2) => {
  ActivityFeelings2["Accomplishment"] = "ACCOMPLISHMENT";
  ActivityFeelings2["Enjoyment"] = "ENJOYMENT";
  return ActivityFeelings2;
})(ActivityFeelings || {});
var AddressType = /* @__PURE__ */ ((AddressType2) => {
  AddressType2["Both"] = "BOTH";
  AddressType2["Physical"] = "PHYSICAL";
  AddressType2["Postal"] = "POSTAL";
  return AddressType2;
})(AddressType || {});
var AddressUse = /* @__PURE__ */ ((AddressUse2) => {
  AddressUse2["Home"] = "HOME";
  AddressUse2["Insurance"] = "INSURANCE";
  AddressUse2["Supplies"] = "SUPPLIES";
  AddressUse2["Temp"] = "TEMP";
  AddressUse2["Work"] = "WORK";
  return AddressUse2;
})(AddressUse || {});
var AiConversationSearchSortOrder = /* @__PURE__ */ ((AiConversationSearchSortOrder2) => {
  AiConversationSearchSortOrder2["CreatedAsc"] = "CREATED_ASC";
  AiConversationSearchSortOrder2["CreatedDesc"] = "CREATED_DESC";
  AiConversationSearchSortOrder2["LastActivityAsc"] = "LAST_ACTIVITY_ASC";
  AiConversationSearchSortOrder2["LastActivityDesc"] = "LAST_ACTIVITY_DESC";
  AiConversationSearchSortOrder2["TotalMessageCountAsc"] = "TOTAL_MESSAGE_COUNT_ASC";
  AiConversationSearchSortOrder2["TotalMessageCountDesc"] = "TOTAL_MESSAGE_COUNT_DESC";
  return AiConversationSearchSortOrder2;
})(AiConversationSearchSortOrder || {});
var AiMessageSearchSortOrder = /* @__PURE__ */ ((AiMessageSearchSortOrder2) => {
  AiMessageSearchSortOrder2["CreatedAsc"] = "CREATED_ASC";
  AiMessageSearchSortOrder2["CreatedDesc"] = "CREATED_DESC";
  return AiMessageSearchSortOrder2;
})(AiMessageSearchSortOrder || {});
var AppointmentTypes = /* @__PURE__ */ ((AppointmentTypes2) => {
  AppointmentTypes2["AsamAssessment"] = "ASAM_ASSESSMENT";
  AppointmentTypes2["CareConcierge"] = "CARE_CONCIERGE";
  AppointmentTypes2["CaseManagement"] = "CASE_MANAGEMENT";
  AppointmentTypes2["CounselorAppointment"] = "COUNSELOR_APPOINTMENT";
  AppointmentTypes2["CounselorAssessment"] = "COUNSELOR_ASSESSMENT";
  AppointmentTypes2["IntakeScreening"] = "INTAKE_SCREENING";
  AppointmentTypes2["MedicalAppointment"] = "MEDICAL_APPOINTMENT";
  AppointmentTypes2["MedicalAssessment"] = "MEDICAL_ASSESSMENT";
  AppointmentTypes2["PeerSupport"] = "PEER_SUPPORT";
  return AppointmentTypes2;
})(AppointmentTypes || {});
var AreaOfConcernType = /* @__PURE__ */ ((AreaOfConcernType2) => {
  AreaOfConcernType2["SystemDefault"] = "SYSTEM_DEFAULT";
  AreaOfConcernType2["UserCustom"] = "USER_CUSTOM";
  return AreaOfConcernType2;
})(AreaOfConcernType || {});
var AreasOfLife = /* @__PURE__ */ ((AreasOfLife2) => {
  AreasOfLife2["FamilyRelationships"] = "FAMILY_RELATIONSHIPS";
  AreasOfLife2["HousingFinancialLegal"] = "HOUSING_FINANCIAL_LEGAL";
  AreasOfLife2["MentalHealth"] = "MENTAL_HEALTH";
  AreasOfLife2["PhysicalHealth"] = "PHYSICAL_HEALTH";
  AreasOfLife2["SocialLifeAndFriends"] = "SOCIAL_LIFE_AND_FRIENDS";
  AreasOfLife2["WorkOrSchool"] = "WORK_OR_SCHOOL";
  return AreasOfLife2;
})(AreasOfLife || {});
var AreasOfLifeHabitImpact = /* @__PURE__ */ ((AreasOfLifeHabitImpact2) => {
  AreasOfLifeHabitImpact2["Happiness"] = "HAPPINESS";
  AreasOfLifeHabitImpact2["Health"] = "HEALTH";
  AreasOfLifeHabitImpact2["Mood"] = "MOOD";
  AreasOfLifeHabitImpact2["Relationships"] = "RELATIONSHIPS";
  AreasOfLifeHabitImpact2["Work"] = "WORK";
  return AreasOfLifeHabitImpact2;
})(AreasOfLifeHabitImpact || {});
var BirthSex = /* @__PURE__ */ ((BirthSex2) => {
  BirthSex2["Female"] = "FEMALE";
  BirthSex2["Male"] = "MALE";
  return BirthSex2;
})(BirthSex || {});
var CalendarEventStatuses = /* @__PURE__ */ ((CalendarEventStatuses2) => {
  CalendarEventStatuses2["Canceled"] = "CANCELED";
  CalendarEventStatuses2["CanceledAttendeeNoshow"] = "CANCELED_ATTENDEE_NOSHOW";
  CalendarEventStatuses2["CanceledByClient"] = "CANCELED_BY_CLIENT";
  CalendarEventStatuses2["CanceledByTherapist"] = "CANCELED_BY_THERAPIST";
  CalendarEventStatuses2["CanceledClientNoshow"] = "CANCELED_CLIENT_NOSHOW";
  CalendarEventStatuses2["CanceledTechnicalIssue"] = "CANCELED_TECHNICAL_ISSUE";
  CalendarEventStatuses2["CanceledTherapistNoshow"] = "CANCELED_THERAPIST_NOSHOW";
  CalendarEventStatuses2["Completed"] = "COMPLETED";
  CalendarEventStatuses2["CompletedExternally"] = "COMPLETED_EXTERNALLY";
  CalendarEventStatuses2["CompletedNonBillable"] = "COMPLETED_NON_BILLABLE";
  CalendarEventStatuses2["Confirmed"] = "CONFIRMED";
  CalendarEventStatuses2["InProgress"] = "IN_PROGRESS";
  CalendarEventStatuses2["NeedsAttention"] = "NEEDS_ATTENTION";
  CalendarEventStatuses2["NeedsAttentionNoAttendees"] = "NEEDS_ATTENTION_NO_ATTENDEES";
  CalendarEventStatuses2["NeedsAttentionNoClient"] = "NEEDS_ATTENTION_NO_CLIENT";
  CalendarEventStatuses2["NeedsAttentionNoTherapist"] = "NEEDS_ATTENTION_NO_THERAPIST";
  CalendarEventStatuses2["Noshow"] = "NOSHOW";
  CalendarEventStatuses2["Unconfirmed"] = "UNCONFIRMED";
  return CalendarEventStatuses2;
})(CalendarEventStatuses || {});
var CalendarEventTypes = /* @__PURE__ */ ((CalendarEventTypes2) => {
  CalendarEventTypes2["Appointment"] = "APPOINTMENT";
  CalendarEventTypes2["Available"] = "AVAILABLE";
  CalendarEventTypes2["Block"] = "BLOCK";
  CalendarEventTypes2["Meeting"] = "MEETING";
  CalendarEventTypes2["Reservation"] = "RESERVATION";
  CalendarEventTypes2["Task"] = "TASK";
  return CalendarEventTypes2;
})(CalendarEventTypes || {});
var CheckinType = /* @__PURE__ */ ((CheckinType2) => {
  CheckinType2["Full"] = "FULL";
  CheckinType2["Quick"] = "QUICK";
  return CheckinType2;
})(CheckinType || {});
var CommunityGroupVisibility = /* @__PURE__ */ ((CommunityGroupVisibility2) => {
  CommunityGroupVisibility2["Private"] = "PRIVATE";
  CommunityGroupVisibility2["Public"] = "PUBLIC";
  return CommunityGroupVisibility2;
})(CommunityGroupVisibility || {});
var CommunityStatus = /* @__PURE__ */ ((CommunityStatus2) => {
  CommunityStatus2["Active"] = "ACTIVE";
  CommunityStatus2["Blocked"] = "BLOCKED";
  CommunityStatus2["Onboarding"] = "ONBOARDING";
  return CommunityStatus2;
})(CommunityStatus || {});
var CommunityUserAvatarBackgroundColor = /* @__PURE__ */ ((CommunityUserAvatarBackgroundColor2) => {
  CommunityUserAvatarBackgroundColor2["Blank"] = "BLANK";
  CommunityUserAvatarBackgroundColor2["Default"] = "DEFAULT";
  CommunityUserAvatarBackgroundColor2["Variant2"] = "VARIANT2";
  CommunityUserAvatarBackgroundColor2["Variant3"] = "VARIANT3";
  CommunityUserAvatarBackgroundColor2["Variant4"] = "VARIANT4";
  CommunityUserAvatarBackgroundColor2["Variant5"] = "VARIANT5";
  CommunityUserAvatarBackgroundColor2["Variant6"] = "VARIANT6";
  CommunityUserAvatarBackgroundColor2["Variant7"] = "VARIANT7";
  CommunityUserAvatarBackgroundColor2["Variant8"] = "VARIANT8";
  return CommunityUserAvatarBackgroundColor2;
})(CommunityUserAvatarBackgroundColor || {});
var CommunityUserAvatarSvgIcon = /* @__PURE__ */ ((CommunityUserAvatarSvgIcon2) => {
  CommunityUserAvatarSvgIcon2["Alien"] = "ALIEN";
  CommunityUserAvatarSvgIcon2["Alligator"] = "ALLIGATOR";
  CommunityUserAvatarSvgIcon2["Bear"] = "BEAR";
  CommunityUserAvatarSvgIcon2["Beaver"] = "BEAVER";
  CommunityUserAvatarSvgIcon2["Brain"] = "BRAIN";
  CommunityUserAvatarSvgIcon2["Cat"] = "CAT";
  CommunityUserAvatarSvgIcon2["Chicken"] = "CHICKEN";
  CommunityUserAvatarSvgIcon2["Cow"] = "COW";
  CommunityUserAvatarSvgIcon2["Deer"] = "DEER";
  CommunityUserAvatarSvgIcon2["Dog"] = "DOG";
  CommunityUserAvatarSvgIcon2["Duck"] = "DUCK";
  CommunityUserAvatarSvgIcon2["Fox"] = "FOX";
  CommunityUserAvatarSvgIcon2["Giraffe"] = "GIRAFFE";
  CommunityUserAvatarSvgIcon2["Horse"] = "HORSE";
  CommunityUserAvatarSvgIcon2["Koala"] = "KOALA";
  CommunityUserAvatarSvgIcon2["Octopus"] = "OCTOPUS";
  CommunityUserAvatarSvgIcon2["Owl"] = "OWL";
  CommunityUserAvatarSvgIcon2["Panda"] = "PANDA";
  CommunityUserAvatarSvgIcon2["Penguin"] = "PENGUIN";
  CommunityUserAvatarSvgIcon2["Pig"] = "PIG";
  CommunityUserAvatarSvgIcon2["Rabbit"] = "RABBIT";
  CommunityUserAvatarSvgIcon2["Robot"] = "ROBOT";
  CommunityUserAvatarSvgIcon2["Rocket"] = "ROCKET";
  CommunityUserAvatarSvgIcon2["Rose"] = "ROSE";
  CommunityUserAvatarSvgIcon2["Shark"] = "SHARK";
  CommunityUserAvatarSvgIcon2["Sheep"] = "SHEEP";
  return CommunityUserAvatarSvgIcon2;
})(CommunityUserAvatarSvgIcon || {});
var CommunityUserAvatarType = /* @__PURE__ */ ((CommunityUserAvatarType2) => {
  CommunityUserAvatarType2["AvatarSvgIcon"] = "AVATAR_SVG_ICON";
  CommunityUserAvatarType2["ProfilePhoto"] = "PROFILE_PHOTO";
  return CommunityUserAvatarType2;
})(CommunityUserAvatarType || {});
var ConversationReviewStatus = /* @__PURE__ */ ((ConversationReviewStatus2) => {
  ConversationReviewStatus2["InProgress"] = "IN_PROGRESS";
  ConversationReviewStatus2["Reviewed"] = "REVIEWED";
  return ConversationReviewStatus2;
})(ConversationReviewStatus || {});
var DoesUseSubstanceMoreThanUsuallyFrequencyPattern = /* @__PURE__ */ ((DoesUseSubstanceMoreThanUsuallyFrequencyPattern2) => {
  DoesUseSubstanceMoreThanUsuallyFrequencyPattern2["OneToTwoTimesPerMonth"] = "ONE_TO_TWO_TIMES_PER_MONTH";
  DoesUseSubstanceMoreThanUsuallyFrequencyPattern2["OneToTwoTimesPerWeek"] = "ONE_TO_TWO_TIMES_PER_WEEK";
  DoesUseSubstanceMoreThanUsuallyFrequencyPattern2["Other"] = "OTHER";
  return DoesUseSubstanceMoreThanUsuallyFrequencyPattern2;
})(DoesUseSubstanceMoreThanUsuallyFrequencyPattern || {});
var DownloadEbookSupportedCondition = /* @__PURE__ */ ((DownloadEbookSupportedCondition2) => {
  DownloadEbookSupportedCondition2["Alcohol"] = "ALCOHOL";
  DownloadEbookSupportedCondition2["Cannabis"] = "CANNABIS";
  DownloadEbookSupportedCondition2["CompulsiveMobilePhoneOrInternetUse"] = "COMPULSIVE_MOBILE_PHONE_OR_INTERNET_USE";
  DownloadEbookSupportedCondition2["CompulsivePornograpy"] = "COMPULSIVE_PORNOGRAPY";
  DownloadEbookSupportedCondition2["CompulsiveSexualBehavior"] = "COMPULSIVE_SEXUAL_BEHAVIOR";
  DownloadEbookSupportedCondition2["CompulsiveShopping"] = "COMPULSIVE_SHOPPING";
  DownloadEbookSupportedCondition2["CompulsiveSocialMediaUse"] = "COMPULSIVE_SOCIAL_MEDIA_USE";
  DownloadEbookSupportedCondition2["CompulsiveVideoGaming"] = "COMPULSIVE_VIDEO_GAMING";
  DownloadEbookSupportedCondition2["CompulsiveVideoStreamingOrTvUse"] = "COMPULSIVE_VIDEO_STREAMING_OR_TV_USE";
  DownloadEbookSupportedCondition2["Depressants"] = "DEPRESSANTS";
  DownloadEbookSupportedCondition2["Gambling"] = "GAMBLING";
  DownloadEbookSupportedCondition2["NicotineAndTobacco"] = "NICOTINE_AND_TOBACCO";
  DownloadEbookSupportedCondition2["OpioidsOrPainkillers"] = "OPIOIDS_OR_PAINKILLERS";
  DownloadEbookSupportedCondition2["Stimulants"] = "STIMULANTS";
  return DownloadEbookSupportedCondition2;
})(DownloadEbookSupportedCondition || {});
var DrawbackOrConcernCategory = /* @__PURE__ */ ((DrawbackOrConcernCategory2) => {
  DrawbackOrConcernCategory2["Custom"] = "CUSTOM";
  DrawbackOrConcernCategory2["Family"] = "FAMILY";
  DrawbackOrConcernCategory2["FinancialImpact"] = "FINANCIAL_IMPACT";
  DrawbackOrConcernCategory2["Friends"] = "FRIENDS";
  DrawbackOrConcernCategory2["LegalIssues"] = "LEGAL_ISSUES";
  DrawbackOrConcernCategory2["MentalHealth"] = "MENTAL_HEALTH";
  DrawbackOrConcernCategory2["Other"] = "OTHER";
  DrawbackOrConcernCategory2["PhysicalHealth"] = "PHYSICAL_HEALTH";
  DrawbackOrConcernCategory2["SocialStigma"] = "SOCIAL_STIGMA";
  DrawbackOrConcernCategory2["WorkOrProfessionalLife"] = "WORK_OR_PROFESSIONAL_LIFE";
  return DrawbackOrConcernCategory2;
})(DrawbackOrConcernCategory || {});
var EducationLevel = /* @__PURE__ */ ((EducationLevel2) => {
  EducationLevel2["AssociateDegree"] = "ASSOCIATE_DEGREE";
  EducationLevel2["BachelorsDegree"] = "BACHELORS_DEGREE";
  EducationLevel2["CertificateProgram"] = "CERTIFICATE_PROGRAM";
  EducationLevel2["DoctoralDegree"] = "DOCTORAL_DEGREE";
  EducationLevel2["Gradeschool"] = "GRADESCHOOL";
  EducationLevel2["HighSchool"] = "HIGH_SCHOOL";
  EducationLevel2["MastersDegree"] = "MASTERS_DEGREE";
  EducationLevel2["ProfessionalDegree"] = "PROFESSIONAL_DEGREE";
  EducationLevel2["TradeSchool"] = "TRADE_SCHOOL";
  return EducationLevel2;
})(EducationLevel || {});
var EmailType = /* @__PURE__ */ ((EmailType2) => {
  EmailType2["All"] = "ALL";
  EmailType2["Marketing"] = "MARKETING";
  EmailType2["NewFeature"] = "NEW_FEATURE";
  EmailType2["Product"] = "PRODUCT";
  EmailType2["Promotion"] = "PROMOTION";
  EmailType2["Survey"] = "SURVEY";
  return EmailType2;
})(EmailType || {});
var EmojiReaction = /* @__PURE__ */ ((EmojiReaction2) => {
  EmojiReaction2["Clap"] = "CLAP";
  EmojiReaction2["Cool"] = "COOL";
  EmojiReaction2["Crying"] = "CRYING";
  EmojiReaction2["Fire"] = "FIRE";
  EmojiReaction2["Flushed"] = "FLUSHED";
  EmojiReaction2["Heart"] = "HEART";
  EmojiReaction2["Hearteyes"] = "HEARTEYES";
  EmojiReaction2["Hundred"] = "HUNDRED";
  EmojiReaction2["Mendingheart"] = "MENDINGHEART";
  EmojiReaction2["Mindblown"] = "MINDBLOWN";
  EmojiReaction2["Muscle"] = "MUSCLE";
  EmojiReaction2["Party"] = "PARTY";
  EmojiReaction2["Pleading"] = "PLEADING";
  EmojiReaction2["Rainbow"] = "RAINBOW";
  EmojiReaction2["Relieved"] = "RELIEVED";
  EmojiReaction2["Sad"] = "SAD";
  EmojiReaction2["Screaming"] = "SCREAMING";
  EmojiReaction2["Smile"] = "SMILE";
  EmojiReaction2["Sparkles"] = "SPARKLES";
  EmojiReaction2["Star"] = "STAR";
  EmojiReaction2["Thinking"] = "THINKING";
  return EmojiReaction2;
})(EmojiReaction || {});
var EmploymentDurationType = /* @__PURE__ */ ((EmploymentDurationType2) => {
  EmploymentDurationType2["Days"] = "DAYS";
  EmploymentDurationType2["Months"] = "MONTHS";
  EmploymentDurationType2["Weeks"] = "WEEKS";
  EmploymentDurationType2["Years"] = "YEARS";
  return EmploymentDurationType2;
})(EmploymentDurationType || {});
var EmploymentStatus = /* @__PURE__ */ ((EmploymentStatus2) => {
  EmploymentStatus2["DayLaborer"] = "DAY_LABORER";
  EmploymentStatus2["Disabled"] = "DISABLED";
  EmploymentStatus2["FullTime"] = "FULL_TIME";
  EmploymentStatus2["FullTimeStudent"] = "FULL_TIME_STUDENT";
  EmploymentStatus2["Homemaker"] = "HOMEMAKER";
  EmploymentStatus2["Other"] = "OTHER";
  EmploymentStatus2["PartTime"] = "PART_TIME";
  EmploymentStatus2["PreferNotToSay"] = "PREFER_NOT_TO_SAY";
  EmploymentStatus2["Retired"] = "RETIRED";
  EmploymentStatus2["Unemployed"] = "UNEMPLOYED";
  return EmploymentStatus2;
})(EmploymentStatus || {});
var EncounterNotesAiStatus = /* @__PURE__ */ ((EncounterNotesAiStatus2) => {
  EncounterNotesAiStatus2["Complete"] = "COMPLETE";
  EncounterNotesAiStatus2["Failed"] = "FAILED";
  EncounterNotesAiStatus2["Missing"] = "MISSING";
  EncounterNotesAiStatus2["Pending"] = "PENDING";
  return EncounterNotesAiStatus2;
})(EncounterNotesAiStatus || {});
var EncounterNotesClinicianStatus = /* @__PURE__ */ ((EncounterNotesClinicianStatus2) => {
  EncounterNotesClinicianStatus2["Complete"] = "COMPLETE";
  EncounterNotesClinicianStatus2["InProcess"] = "IN_PROCESS";
  EncounterNotesClinicianStatus2["NoneNeeded"] = "NONE_NEEDED";
  EncounterNotesClinicianStatus2["NotStarted"] = "NOT_STARTED";
  EncounterNotesClinicianStatus2["RevertedBackFromSupervisor"] = "REVERTED_BACK_FROM_SUPERVISOR";
  return EncounterNotesClinicianStatus2;
})(EncounterNotesClinicianStatus || {});
var EncounterNotesReasonForMissedAppointment = /* @__PURE__ */ ((EncounterNotesReasonForMissedAppointment2) => {
  EncounterNotesReasonForMissedAppointment2["MissedByClient"] = "MISSED_BY_CLIENT";
  EncounterNotesReasonForMissedAppointment2["MissedByProvider"] = "MISSED_BY_PROVIDER";
  EncounterNotesReasonForMissedAppointment2["Other"] = "OTHER";
  EncounterNotesReasonForMissedAppointment2["TechDifficultyCancelled"] = "TECH_DIFFICULTY_CANCELLED";
  EncounterNotesReasonForMissedAppointment2["TechDifficultyMovedToOtherPlatform"] = "TECH_DIFFICULTY_MOVED_TO_OTHER_PLATFORM";
  return EncounterNotesReasonForMissedAppointment2;
})(EncounterNotesReasonForMissedAppointment || {});
var EncounterNotesSearchSortOrder = /* @__PURE__ */ ((EncounterNotesSearchSortOrder2) => {
  EncounterNotesSearchSortOrder2["AppointmentStartAsc"] = "APPOINTMENT_START_ASC";
  EncounterNotesSearchSortOrder2["AppointmentStartDesc"] = "APPOINTMENT_START_DESC";
  EncounterNotesSearchSortOrder2["CreatedAsc"] = "CREATED_ASC";
  EncounterNotesSearchSortOrder2["CreatedDesc"] = "CREATED_DESC";
  EncounterNotesSearchSortOrder2["LastUpdatedAsc"] = "LAST_UPDATED_ASC";
  EncounterNotesSearchSortOrder2["LastUpdatedDesc"] = "LAST_UPDATED_DESC";
  return EncounterNotesSearchSortOrder2;
})(EncounterNotesSearchSortOrder || {});
var EncounterNotesStatus = /* @__PURE__ */ ((EncounterNotesStatus2) => {
  EncounterNotesStatus2["Archived"] = "ARCHIVED";
  EncounterNotesStatus2["Draft"] = "DRAFT";
  EncounterNotesStatus2["Finalized"] = "FINALIZED";
  EncounterNotesStatus2["InProcess"] = "IN_PROCESS";
  EncounterNotesStatus2["MissedAppointment"] = "MISSED_APPOINTMENT";
  return EncounterNotesStatus2;
})(EncounterNotesStatus || {});
var EncounterNotesSupervisorStatus = /* @__PURE__ */ ((EncounterNotesSupervisorStatus2) => {
  EncounterNotesSupervisorStatus2["AwaitingReview"] = "AWAITING_REVIEW";
  EncounterNotesSupervisorStatus2["Complete"] = "COMPLETE";
  EncounterNotesSupervisorStatus2["InProcess"] = "IN_PROCESS";
  EncounterNotesSupervisorStatus2["NoneNeeded"] = "NONE_NEEDED";
  EncounterNotesSupervisorStatus2["NotStarted"] = "NOT_STARTED";
  EncounterNotesSupervisorStatus2["RevertedBack"] = "REVERTED_BACK";
  return EncounterNotesSupervisorStatus2;
})(EncounterNotesSupervisorStatus || {});
var EntryType = /* @__PURE__ */ ((EntryType2) => {
  EntryType2["Full"] = "FULL";
  EntryType2["Quick"] = "QUICK";
  return EntryType2;
})(EntryType || {});
var FamilyRelationshipType = /* @__PURE__ */ ((FamilyRelationshipType2) => {
  FamilyRelationshipType2["AuntOrUncle"] = "AUNT_OR_UNCLE";
  FamilyRelationshipType2["Child"] = "CHILD";
  FamilyRelationshipType2["Cousin"] = "COUSIN";
  FamilyRelationshipType2["Grandchild"] = "GRANDCHILD";
  FamilyRelationshipType2["Grandparent"] = "GRANDPARENT";
  FamilyRelationshipType2["InLaw"] = "IN_LAW";
  FamilyRelationshipType2["NieceOrNephew"] = "NIECE_OR_NEPHEW";
  FamilyRelationshipType2["Other"] = "OTHER";
  FamilyRelationshipType2["Parent"] = "PARENT";
  FamilyRelationshipType2["PartnerOrEx"] = "PARTNER_OR_EX";
  FamilyRelationshipType2["Sibling"] = "SIBLING";
  FamilyRelationshipType2["Spouse"] = "SPOUSE";
  return FamilyRelationshipType2;
})(FamilyRelationshipType || {});
var FeelingsAfterTechnique = /* @__PURE__ */ ((FeelingsAfterTechnique2) => {
  FeelingsAfterTechnique2["ALittleBetter"] = "A_LITTLE_BETTER";
  FeelingsAfterTechnique2["ALittleWorse"] = "A_LITTLE_WORSE";
  FeelingsAfterTechnique2["MuchBetter"] = "MUCH_BETTER";
  FeelingsAfterTechnique2["MuchWorse"] = "MUCH_WORSE";
  FeelingsAfterTechnique2["Unchanged"] = "UNCHANGED";
  return FeelingsAfterTechnique2;
})(FeelingsAfterTechnique || {});
var FileAttachmentStatus = /* @__PURE__ */ ((FileAttachmentStatus2) => {
  FileAttachmentStatus2["Archived"] = "ARCHIVED";
  FileAttachmentStatus2["Completed"] = "COMPLETED";
  FileAttachmentStatus2["Created"] = "CREATED";
  FileAttachmentStatus2["InProgress"] = "IN_PROGRESS";
  return FileAttachmentStatus2;
})(FileAttachmentStatus || {});
var FilesSearchSortOrder = /* @__PURE__ */ ((FilesSearchSortOrder2) => {
  FilesSearchSortOrder2["CreatedAsc"] = "CREATED_ASC";
  FilesSearchSortOrder2["CreatedDesc"] = "CREATED_DESC";
  FilesSearchSortOrder2["NameAsc"] = "NAME_ASC";
  FilesSearchSortOrder2["NameDesc"] = "NAME_DESC";
  return FilesSearchSortOrder2;
})(FilesSearchSortOrder || {});
var ForYouCardType = /* @__PURE__ */ ((ForYouCardType2) => {
  ForYouCardType2["Article"] = "ARTICLE";
  ForYouCardType2["Podcast"] = "PODCAST";
  ForYouCardType2["ThoughtRecord"] = "THOUGHT_RECORD";
  ForYouCardType2["Video"] = "VIDEO";
  return ForYouCardType2;
})(ForYouCardType || {});
var FormStatus = /* @__PURE__ */ ((FormStatus2) => {
  FormStatus2["NotSent"] = "NOT_SENT";
  FormStatus2["Sent"] = "SENT";
  FormStatus2["Signed"] = "SIGNED";
  return FormStatus2;
})(FormStatus || {});
var FriendStatus = /* @__PURE__ */ ((FriendStatus2) => {
  FriendStatus2["Friend"] = "FRIEND";
  FriendStatus2["NotFriend"] = "NOT_FRIEND";
  FriendStatus2["Pending"] = "PENDING";
  return FriendStatus2;
})(FriendStatus || {});
var GenderIdentity = /* @__PURE__ */ ((GenderIdentity2) => {
  GenderIdentity2["Female"] = "FEMALE";
  GenderIdentity2["Male"] = "MALE";
  GenderIdentity2["NonBinary"] = "NON_BINARY";
  GenderIdentity2["PreferNotToSay"] = "PREFER_NOT_TO_SAY";
  return GenderIdentity2;
})(GenderIdentity || {});
var HabitAndSubstanceList = /* @__PURE__ */ ((HabitAndSubstanceList2) => {
  HabitAndSubstanceList2["Alcohol"] = "ALCOHOL";
  HabitAndSubstanceList2["Cocaine"] = "COCAINE";
  HabitAndSubstanceList2["CompulsiveSex"] = "COMPULSIVE_SEX";
  HabitAndSubstanceList2["Depressants"] = "DEPRESSANTS";
  HabitAndSubstanceList2["Gambling"] = "GAMBLING";
  HabitAndSubstanceList2["Hallucinogens"] = "HALLUCINOGENS";
  HabitAndSubstanceList2["Inhalants"] = "INHALANTS";
  HabitAndSubstanceList2["MarijuanaCannabis"] = "MARIJUANA_CANNABIS";
  HabitAndSubstanceList2["Methamphetamines"] = "METHAMPHETAMINES";
  HabitAndSubstanceList2["MobilePhoneInternet"] = "MOBILE_PHONE_INTERNET";
  HabitAndSubstanceList2["NicotineTobacco"] = "NICOTINE_TOBACCO";
  HabitAndSubstanceList2["OpioidsOrPainKillers"] = "OPIOIDS_OR_PAIN_KILLERS";
  HabitAndSubstanceList2["Other"] = "OTHER";
  HabitAndSubstanceList2["PornRomanceNovels"] = "PORN_ROMANCE_NOVELS";
  HabitAndSubstanceList2["Shopping"] = "SHOPPING";
  HabitAndSubstanceList2["SocialMedia"] = "SOCIAL_MEDIA";
  HabitAndSubstanceList2["Stimulants"] = "STIMULANTS";
  HabitAndSubstanceList2["TvVideos"] = "TV_VIDEOS";
  HabitAndSubstanceList2["VideoGames"] = "VIDEO_GAMES";
  return HabitAndSubstanceList2;
})(HabitAndSubstanceList || {});
var HabitBenefitAssessment = /* @__PURE__ */ ((HabitBenefitAssessment2) => {
  HabitBenefitAssessment2["AlwaysBeneficialWithNoProblems"] = "ALWAYS_BENEFICIAL_WITH_NO_PROBLEMS";
  HabitBenefitAssessment2["SometimesProblemsAssociated"] = "SOMETIMES_PROBLEMS_ASSOCIATED";
  return HabitBenefitAssessment2;
})(HabitBenefitAssessment || {});
var HabitCategory = /* @__PURE__ */ ((HabitCategory2) => {
  HabitCategory2["Habit"] = "HABIT";
  HabitCategory2["Other"] = "OTHER";
  HabitCategory2["Substance"] = "SUBSTANCE";
  return HabitCategory2;
})(HabitCategory || {});
var HabitOrSubstanceUseFrequencyPattern = /* @__PURE__ */ ((HabitOrSubstanceUseFrequencyPattern2) => {
  HabitOrSubstanceUseFrequencyPattern2["EveryDay"] = "EVERY_DAY";
  HabitOrSubstanceUseFrequencyPattern2["FewTimesPerMonth"] = "FEW_TIMES_PER_MONTH";
  HabitOrSubstanceUseFrequencyPattern2["FewTimesPerWeek"] = "FEW_TIMES_PER_WEEK";
  HabitOrSubstanceUseFrequencyPattern2["FewTimesPerYear"] = "FEW_TIMES_PER_YEAR";
  HabitOrSubstanceUseFrequencyPattern2["OnlyWeekendsOrDaysOff"] = "ONLY_WEEKENDS_OR_DAYS_OFF";
  HabitOrSubstanceUseFrequencyPattern2["OnlyWorkOrSchoolDays"] = "ONLY_WORK_OR_SCHOOL_DAYS";
  return HabitOrSubstanceUseFrequencyPattern2;
})(HabitOrSubstanceUseFrequencyPattern || {});
var HabitOrSubstanceUseTimeOfDay = /* @__PURE__ */ ((HabitOrSubstanceUseTimeOfDay2) => {
  HabitOrSubstanceUseTimeOfDay2["Afternoon"] = "AFTERNOON";
  HabitOrSubstanceUseTimeOfDay2["Evening"] = "EVENING";
  HabitOrSubstanceUseTimeOfDay2["Morning"] = "MORNING";
  HabitOrSubstanceUseTimeOfDay2["Night"] = "NIGHT";
  return HabitOrSubstanceUseTimeOfDay2;
})(HabitOrSubstanceUseTimeOfDay || {});
var HabitsList = /* @__PURE__ */ ((HabitsList2) => {
  HabitsList2["CompulsiveSex"] = "COMPULSIVE_SEX";
  HabitsList2["Gambling"] = "GAMBLING";
  HabitsList2["MobilePhoneInternet"] = "MOBILE_PHONE_INTERNET";
  HabitsList2["Other"] = "OTHER";
  HabitsList2["PornRomanceNovels"] = "PORN_ROMANCE_NOVELS";
  HabitsList2["Shopping"] = "SHOPPING";
  HabitsList2["SocialMedia"] = "SOCIAL_MEDIA";
  HabitsList2["TvVideos"] = "TV_VIDEOS";
  HabitsList2["VideoGames"] = "VIDEO_GAMES";
  return HabitsList2;
})(HabitsList || {});
var HowMuchConcern = /* @__PURE__ */ ((HowMuchConcern2) => {
  HowMuchConcern2["NoImpact"] = "NO_IMPACT";
  HowMuchConcern2["Significant"] = "SIGNIFICANT";
  HowMuchConcern2["Some"] = "SOME";
  return HowMuchConcern2;
})(HowMuchConcern || {});
var HowMuchTimeToInvestToBetterManageUnwantedHabits = /* @__PURE__ */ ((HowMuchTimeToInvestToBetterManageUnwantedHabits2) => {
  HowMuchTimeToInvestToBetterManageUnwantedHabits2["About_1HourPerWeek"] = "ABOUT_1_HOUR_PER_WEEK";
  HowMuchTimeToInvestToBetterManageUnwantedHabits2["About_2HoursPerWeek"] = "ABOUT_2_HOURS_PER_WEEK";
  HowMuchTimeToInvestToBetterManageUnwantedHabits2["LessThan_30MinutesPerWeek"] = "LESS_THAN_30_MINUTES_PER_WEEK";
  return HowMuchTimeToInvestToBetterManageUnwantedHabits2;
})(HowMuchTimeToInvestToBetterManageUnwantedHabits || {});
var IntakeStatus = /* @__PURE__ */ ((IntakeStatus2) => {
  IntakeStatus2["AttemptingContact"] = "ATTEMPTING_CONTACT";
  IntakeStatus2["CounselingIntakeCompleted"] = "COUNSELING_INTAKE_COMPLETED";
  IntakeStatus2["IntakesScheduled"] = "INTAKES_SCHEDULED";
  IntakeStatus2["MedicalIntakeCompleted"] = "MEDICAL_INTAKE_COMPLETED";
  IntakeStatus2["MissedIntake"] = "MISSED_INTAKE";
  IntakeStatus2["New"] = "NEW";
  IntakeStatus2["ScreeningComplete"] = "SCREENING_COMPLETE";
  IntakeStatus2["ScreeningCompleteUrgent"] = "SCREENING_COMPLETE_URGENT";
  IntakeStatus2["ScreeningPartiallyCompleted"] = "SCREENING_PARTIALLY_COMPLETED";
  IntakeStatus2["ScreeningScheduled"] = "SCREENING_SCHEDULED";
  IntakeStatus2["WillNotConvert"] = "WILL_NOT_CONVERT";
  return IntakeStatus2;
})(IntakeStatus || {});
var InterferenceFrequencyInDailyLife = /* @__PURE__ */ ((InterferenceFrequencyInDailyLife2) => {
  InterferenceFrequencyInDailyLife2["EveryDay"] = "EVERY_DAY";
  InterferenceFrequencyInDailyLife2["FewTimesPerMonth"] = "FEW_TIMES_PER_MONTH";
  InterferenceFrequencyInDailyLife2["FewTimesPerWeek"] = "FEW_TIMES_PER_WEEK";
  InterferenceFrequencyInDailyLife2["FewTimesPerYear"] = "FEW_TIMES_PER_YEAR";
  InterferenceFrequencyInDailyLife2["OnlyWeekendsOrDaysOff"] = "ONLY_WEEKENDS_OR_DAYS_OFF";
  InterferenceFrequencyInDailyLife2["OnlyWorkOrSchoolDays"] = "ONLY_WORK_OR_SCHOOL_DAYS";
  return InterferenceFrequencyInDailyLife2;
})(InterferenceFrequencyInDailyLife || {});
var InviteStatus = /* @__PURE__ */ ((InviteStatus2) => {
  InviteStatus2["Accepted"] = "ACCEPTED";
  InviteStatus2["Expired"] = "EXPIRED";
  InviteStatus2["Pending"] = "PENDING";
  InviteStatus2["Rejected"] = "REJECTED";
  return InviteStatus2;
})(InviteStatus || {});
var InvitedToDownloadMethod = /* @__PURE__ */ ((InvitedToDownloadMethod2) => {
  InvitedToDownloadMethod2["Email"] = "EMAIL";
  InvitedToDownloadMethod2["Sms"] = "SMS";
  return InvitedToDownloadMethod2;
})(InvitedToDownloadMethod || {});
var InvitedToDownloadStatus = /* @__PURE__ */ ((InvitedToDownloadStatus2) => {
  InvitedToDownloadStatus2["Downloaded"] = "DOWNLOADED";
  InvitedToDownloadStatus2["NotSent"] = "NOT_SENT";
  InvitedToDownloadStatus2["Sent"] = "SENT";
  return InvitedToDownloadStatus2;
})(InvitedToDownloadStatus || {});
var LifeExperienceCategory = /* @__PURE__ */ ((LifeExperienceCategory2) => {
  LifeExperienceCategory2["Abuse"] = "ABUSE";
  LifeExperienceCategory2["Financial"] = "FINANCIAL";
  LifeExperienceCategory2["FriendsAndFamily"] = "FRIENDS_AND_FAMILY";
  LifeExperienceCategory2["Loss"] = "LOSS";
  LifeExperienceCategory2["Neglect"] = "NEGLECT";
  LifeExperienceCategory2["Other"] = "OTHER";
  LifeExperienceCategory2["Personal"] = "PERSONAL";
  LifeExperienceCategory2["Work"] = "WORK";
  return LifeExperienceCategory2;
})(LifeExperienceCategory || {});
var MaritalStatus = /* @__PURE__ */ ((MaritalStatus2) => {
  MaritalStatus2["Divorced"] = "DIVORCED";
  MaritalStatus2["Married"] = "MARRIED";
  MaritalStatus2["Single"] = "SINGLE";
  MaritalStatus2["Widowed"] = "WIDOWED";
  return MaritalStatus2;
})(MaritalStatus || {});
var MediaMessageType = /* @__PURE__ */ ((MediaMessageType2) => {
  MediaMessageType2["SuggestHumanTherapist"] = "SUGGEST_HUMAN_THERAPIST";
  return MediaMessageType2;
})(MediaMessageType || {});
var MilitaryBranch = /* @__PURE__ */ ((MilitaryBranch2) => {
  MilitaryBranch2["AirForce"] = "AIR_FORCE";
  MilitaryBranch2["Army"] = "ARMY";
  MilitaryBranch2["CoastGuard"] = "COAST_GUARD";
  MilitaryBranch2["MarineCorps"] = "MARINE_CORPS";
  MilitaryBranch2["NationalGuard"] = "NATIONAL_GUARD";
  MilitaryBranch2["Navy"] = "NAVY";
  MilitaryBranch2["Other"] = "OTHER";
  MilitaryBranch2["SpaceForce"] = "SPACE_FORCE";
  return MilitaryBranch2;
})(MilitaryBranch || {});
var Mood = /* @__PURE__ */ ((Mood2) => {
  Mood2["Angry"] = "ANGRY";
  Mood2["Anxious"] = "ANXIOUS";
  Mood2["Awful"] = "AWFUL";
  Mood2["Bad"] = "BAD";
  Mood2["Calm"] = "CALM";
  Mood2["Depressed"] = "DEPRESSED";
  Mood2["Excited"] = "EXCITED";
  Mood2["Good"] = "GOOD";
  Mood2["Great"] = "GREAT";
  Mood2["Happy"] = "HAPPY";
  Mood2["Neutral"] = "NEUTRAL";
  Mood2["Okay"] = "OKAY";
  Mood2["Sad"] = "SAD";
  Mood2["Tired"] = "TIRED";
  return Mood2;
})(Mood || {});
var NoteContentType = /* @__PURE__ */ ((NoteContentType2) => {
  NoteContentType2["Markdown"] = "MARKDOWN";
  NoteContentType2["Text"] = "TEXT";
  return NoteContentType2;
})(NoteContentType || {});
var NoteStatus = /* @__PURE__ */ ((NoteStatus2) => {
  NoteStatus2["Deleted"] = "DELETED";
  NoteStatus2["Draft"] = "DRAFT";
  NoteStatus2["Published"] = "PUBLISHED";
  return NoteStatus2;
})(NoteStatus || {});
var NoteType = /* @__PURE__ */ ((NoteType2) => {
  NoteType2["InSession"] = "IN_SESSION";
  NoteType2["Other"] = "OTHER";
  NoteType2["Primary"] = "PRIMARY";
  NoteType2["ReferralNextStep"] = "REFERRAL_NEXT_STEP";
  return NoteType2;
})(NoteType || {});
var PastLifeExperienceEvents = /* @__PURE__ */ ((PastLifeExperienceEvents2) => {
  PastLifeExperienceEvents2["AnAdultSwearingAtOrInsultingYou"] = "AN_ADULT_SWEARING_AT_OR_INSULTING_YOU";
  PastLifeExperienceEvents2["ATimeWhenUsureWhereHomeWas"] = "A_TIME_WHEN_USURE_WHERE_HOME_WAS";
  PastLifeExperienceEvents2["Bullying"] = "BULLYING";
  PastLifeExperienceEvents2["FeelingLikeYourFamilyDidntCareForYou"] = "FEELING_LIKE_YOUR_FAMILY_DIDNT_CARE_FOR_YOU";
  PastLifeExperienceEvents2["LivingWithAdultsWithDepressionOrSuicideAttempts"] = "LIVING_WITH_ADULTS_WITH_DEPRESSION_OR_SUICIDE_ATTEMPTS";
  PastLifeExperienceEvents2["LivingWithAdultsWithMentalIllness"] = "LIVING_WITH_ADULTS_WITH_MENTAL_ILLNESS";
  PastLifeExperienceEvents2["LivingWithAnyoneThatWentToJailOrPrison"] = "LIVING_WITH_ANYONE_THAT_WENT_TO_JAIL_OR_PRISON";
  PastLifeExperienceEvents2["LivingWithSomeoneWithDrinkingOrDrugProblem"] = "LIVING_WITH_SOMEONE_WITH_DRINKING_OR_DRUG_PROBLEM";
  PastLifeExperienceEvents2["LossOfAParentThroughDeath"] = "LOSS_OF_A_PARENT_THROUGH_DEATH";
  PastLifeExperienceEvents2["LossOfAParentThroughDivorceOrAbandonment"] = "LOSS_OF_A_PARENT_THROUGH_DIVORCE_OR_ABANDONMENT";
  PastLifeExperienceEvents2["NotEnoughToEatOrCleanClothesToWear"] = "NOT_ENOUGH_TO_EAT_OR_CLEAN_CLOTHES_TO_WEAR";
  PastLifeExperienceEvents2["PhysicalHarmFromAnAdult"] = "PHYSICAL_HARM_FROM_AN_ADULT";
  PastLifeExperienceEvents2["UnsafeOrNoOneToProtectYou"] = "UNSAFE_OR_NO_ONE_TO_PROTECT_YOU";
  PastLifeExperienceEvents2["UnwantedSexualContact"] = "UNWANTED_SEXUAL_CONTACT";
  return PastLifeExperienceEvents2;
})(PastLifeExperienceEvents || {});
var PastOrRecentLifeExperienceCategory = /* @__PURE__ */ ((PastOrRecentLifeExperienceCategory2) => {
  PastOrRecentLifeExperienceCategory2["Past"] = "PAST";
  PastOrRecentLifeExperienceCategory2["Recent"] = "RECENT";
  return PastOrRecentLifeExperienceCategory2;
})(PastOrRecentLifeExperienceCategory || {});
var PostInteractionType = /* @__PURE__ */ ((PostInteractionType2) => {
  PostInteractionType2["AiDirIdealPlaceToSuggestClinic"] = "AI_DIR_IDEAL_PLACE_TO_SUGGEST_CLINIC";
  PostInteractionType2["AiDirPresentedWithGoodInfo"] = "AI_DIR_PRESENTED_WITH_GOOD_INFO";
  PostInteractionType2["AiDirShouldntHaveSuggestedClinic"] = "AI_DIR_SHOULDNT_HAVE_SUGGESTED_CLINIC";
  PostInteractionType2["AiDirShouldHaveSuggestedClinic"] = "AI_DIR_SHOULD_HAVE_SUGGESTED_CLINIC";
  PostInteractionType2["AiDirStayOnTopicIfExpressedInterest"] = "AI_DIR_STAY_ON_TOPIC_IF_EXPRESSED_INTEREST";
  PostInteractionType2["AiResponseIsHelpful"] = "AI_RESPONSE_IS_HELPFUL";
  PostInteractionType2["AiResponseNotHelpful"] = "AI_RESPONSE_NOT_HELPFUL";
  PostInteractionType2["Click"] = "CLICK";
  PostInteractionType2["ClickedSuggestHumanTherapist"] = "CLICKED_SUGGEST_HUMAN_THERAPIST";
  PostInteractionType2["Comment"] = "COMMENT";
  PostInteractionType2["Emoji"] = "EMOJI";
  PostInteractionType2["PresentedSuggestHumanTherapist"] = "PRESENTED_SUGGEST_HUMAN_THERAPIST";
  PostInteractionType2["Share"] = "SHARE";
  PostInteractionType2["View"] = "VIEW";
  return PostInteractionType2;
})(PostInteractionType || {});
var PostMediaAttachmentType = /* @__PURE__ */ ((PostMediaAttachmentType2) => {
  PostMediaAttachmentType2["Image"] = "IMAGE";
  PostMediaAttachmentType2["Video"] = "VIDEO";
  return PostMediaAttachmentType2;
})(PostMediaAttachmentType || {});
var PostStatus = /* @__PURE__ */ ((PostStatus2) => {
  PostStatus2["Active"] = "ACTIVE";
  PostStatus2["Archived"] = "ARCHIVED";
  PostStatus2["Deleted"] = "DELETED";
  PostStatus2["Draft"] = "DRAFT";
  PostStatus2["Flagged"] = "FLAGGED";
  PostStatus2["PendingReview"] = "PENDING_REVIEW";
  PostStatus2["Reported"] = "REPORTED";
  PostStatus2["Scheduled"] = "SCHEDULED";
  return PostStatus2;
})(PostStatus || {});
var PreferenceTimeOfDay = /* @__PURE__ */ ((PreferenceTimeOfDay2) => {
  PreferenceTimeOfDay2["Afternoon"] = "AFTERNOON";
  PreferenceTimeOfDay2["Evening"] = "EVENING";
  PreferenceTimeOfDay2["Morning"] = "MORNING";
  PreferenceTimeOfDay2["Night"] = "NIGHT";
  return PreferenceTimeOfDay2;
})(PreferenceTimeOfDay || {});
var ProblemOrConcernCategory = /* @__PURE__ */ ((ProblemOrConcernCategory2) => {
  ProblemOrConcernCategory2["FamilyRelationships"] = "FAMILY_RELATIONSHIPS";
  ProblemOrConcernCategory2["Habit"] = "HABIT";
  ProblemOrConcernCategory2["HabitOrSubstance"] = "HABIT_OR_SUBSTANCE";
  ProblemOrConcernCategory2["HousingFinancialLegal"] = "HOUSING_FINANCIAL_LEGAL";
  ProblemOrConcernCategory2["MentalHealth"] = "MENTAL_HEALTH";
  ProblemOrConcernCategory2["Other"] = "OTHER";
  ProblemOrConcernCategory2["PhysicalHealth"] = "PHYSICAL_HEALTH";
  ProblemOrConcernCategory2["SocialLifeAndFriends"] = "SOCIAL_LIFE_AND_FRIENDS";
  ProblemOrConcernCategory2["SubstanceUse"] = "SUBSTANCE_USE";
  ProblemOrConcernCategory2["WorkOrSchool"] = "WORK_OR_SCHOOL";
  return ProblemOrConcernCategory2;
})(ProblemOrConcernCategory || {});
var Pronouns = /* @__PURE__ */ ((Pronouns2) => {
  Pronouns2["HeHim"] = "HE_HIM";
  Pronouns2["PreferNotToSay"] = "PREFER_NOT_TO_SAY";
  Pronouns2["SheHer"] = "SHE_HER";
  Pronouns2["TheyThem"] = "THEY_THEM";
  return Pronouns2;
})(Pronouns || {});
var ProspectStatus = /* @__PURE__ */ ((ProspectStatus2) => {
  ProspectStatus2["AttemptContact"] = "ATTEMPT_CONTACT";
  ProspectStatus2["Complete"] = "COMPLETE";
  ProspectStatus2["Forms"] = "FORMS";
  ProspectStatus2["Initial"] = "INITIAL";
  ProspectStatus2["Invite"] = "INVITE";
  ProspectStatus2["Review"] = "REVIEW";
  ProspectStatus2["Scheduling"] = "SCHEDULING";
  ProspectStatus2["Screening"] = "SCREENING";
  ProspectStatus2["Services"] = "SERVICES";
  return ProspectStatus2;
})(ProspectStatus || {});
var Race = /* @__PURE__ */ ((Race2) => {
  Race2["AlaskaNative"] = "ALASKA_NATIVE";
  Race2["AmericanIndian"] = "AMERICAN_INDIAN";
  Race2["Asian"] = "ASIAN";
  Race2["Black"] = "BLACK";
  Race2["Hispanic"] = "HISPANIC";
  Race2["NativeHawaiian"] = "NATIVE_HAWAIIAN";
  Race2["Other"] = "OTHER";
  Race2["PreferNotToSay"] = "PREFER_NOT_TO_SAY";
  Race2["White"] = "WHITE";
  return Race2;
})(Race || {});
var ReasonsOrBenefitsForUsingCategory = /* @__PURE__ */ ((ReasonsOrBenefitsForUsingCategory2) => {
  ReasonsOrBenefitsForUsingCategory2["DistractionFromOtherHabit"] = "DISTRACTION_FROM_OTHER_HABIT";
  ReasonsOrBenefitsForUsingCategory2["HelpDealWithEmotionalProblems"] = "HELP_DEAL_WITH_EMOTIONAL_PROBLEMS";
  ReasonsOrBenefitsForUsingCategory2["HelpDealWithHealthProblems"] = "HELP_DEAL_WITH_HEALTH_PROBLEMS";
  ReasonsOrBenefitsForUsingCategory2["HelpDealWithSocialSituations"] = "HELP_DEAL_WITH_SOCIAL_SITUATIONS";
  ReasonsOrBenefitsForUsingCategory2["ILikeHowItMakesMeFeel"] = "I_LIKE_HOW_IT_MAKES_ME_FEEL";
  ReasonsOrBenefitsForUsingCategory2["JustToFeelNormal"] = "JUST_TO_FEEL_NORMAL";
  ReasonsOrBenefitsForUsingCategory2["Other"] = "OTHER";
  ReasonsOrBenefitsForUsingCategory2["PerformBetterAtSchoolOrWork"] = "PERFORM_BETTER_AT_SCHOOL_OR_WORK";
  ReasonsOrBenefitsForUsingCategory2["ToCalmNerves"] = "TO_CALM_NERVES";
  ReasonsOrBenefitsForUsingCategory2["ToCalmThoughts"] = "TO_CALM_THOUGHTS";
  ReasonsOrBenefitsForUsingCategory2["ToEscapeNegativeThoughts"] = "TO_ESCAPE_NEGATIVE_THOUGHTS";
  ReasonsOrBenefitsForUsingCategory2["ToFillBoredom"] = "TO_FILL_BOREDOM";
  ReasonsOrBenefitsForUsingCategory2["ToFunction"] = "TO_FUNCTION";
  ReasonsOrBenefitsForUsingCategory2["ToGetHighBuzz"] = "TO_GET_HIGH_BUZZ";
  ReasonsOrBenefitsForUsingCategory2["ToHaveASpiritualExperience"] = "TO_HAVE_A_SPIRITUAL_EXPERIENCE";
  ReasonsOrBenefitsForUsingCategory2["ToHelpSleep"] = "TO_HELP_SLEEP";
  ReasonsOrBenefitsForUsingCategory2["ToImproveMood"] = "TO_IMPROVE_MOOD";
  ReasonsOrBenefitsForUsingCategory2["ToLoseWeight"] = "TO_LOSE_WEIGHT";
  ReasonsOrBenefitsForUsingCategory2["ToManageStress"] = "TO_MANAGE_STRESS";
  ReasonsOrBenefitsForUsingCategory2["ToReducePain"] = "TO_REDUCE_PAIN";
  return ReasonsOrBenefitsForUsingCategory2;
})(ReasonsOrBenefitsForUsingCategory || {});
var RecentLifeExperienceEvents = /* @__PURE__ */ ((RecentLifeExperienceEvents2) => {
  RecentLifeExperienceEvents2["BossTroubleOrDifficulties"] = "BOSS_TROUBLE_OR_DIFFICULTIES";
  RecentLifeExperienceEvents2["BusinessFailure"] = "BUSINESS_FAILURE";
  RecentLifeExperienceEvents2["ChangeInFinancialState"] = "CHANGE_IN_FINANCIAL_STATE";
  RecentLifeExperienceEvents2["ChildLeavingReturningHome"] = "CHILD_LEAVING_RETURNING_HOME";
  RecentLifeExperienceEvents2["DeathOfAnImmediateFamilyMember"] = "DEATH_OF_AN_IMMEDIATE_FAMILY_MEMBER";
  RecentLifeExperienceEvents2["DeathOfACloseFriendOrOtherFamilyMember"] = "DEATH_OF_A_CLOSE_FRIEND_OR_OTHER_FAMILY_MEMBER";
  RecentLifeExperienceEvents2["DivorceOrSeparation"] = "DIVORCE_OR_SEPARATION";
  RecentLifeExperienceEvents2["FamilyMemberIncarcerated"] = "FAMILY_MEMBER_INCARCERATED";
  RecentLifeExperienceEvents2["FiredOrLaidOff"] = "FIRED_OR_LAID_OFF";
  RecentLifeExperienceEvents2["HousingDifficulties"] = "HOUSING_DIFFICULTIES";
  RecentLifeExperienceEvents2["IncacerationOrLegalProblems"] = "INCACERATION_OR_LEGAL_PROBLEMS";
  RecentLifeExperienceEvents2["MajorFinancialDifficulties"] = "MAJOR_FINANCIAL_DIFFICULTIES";
  RecentLifeExperienceEvents2["MarriedOrRemarried"] = "MARRIED_OR_REMARRIED";
  RecentLifeExperienceEvents2["MiscarriageOrStillbirth"] = "MISCARRIAGE_OR_STILLBIRTH";
  RecentLifeExperienceEvents2["Moving"] = "MOVING";
  RecentLifeExperienceEvents2["NewFamilyMember"] = "NEW_FAMILY_MEMBER";
  RecentLifeExperienceEvents2["NewJob"] = "NEW_JOB";
  RecentLifeExperienceEvents2["PregnancyOrBirth"] = "PREGNANCY_OR_BIRTH";
  RecentLifeExperienceEvents2["RacialAbuseAttackOrThreats"] = "RACIAL_ABUSE_ATTACK_OR_THREATS";
  RecentLifeExperienceEvents2["ResponsibilitiesHaveChanged"] = "RESPONSIBILITIES_HAVE_CHANGED";
  RecentLifeExperienceEvents2["RobbedOrVictimized"] = "ROBBED_OR_VICTIMIZED";
  RecentLifeExperienceEvents2["SeriousIllnessOrInjury"] = "SERIOUS_ILLNESS_OR_INJURY";
  RecentLifeExperienceEvents2["SeriousInjuryOrIllness"] = "SERIOUS_INJURY_OR_ILLNESS";
  RecentLifeExperienceEvents2["SeriousRelationshipProblems"] = "SERIOUS_RELATIONSHIP_PROBLEMS";
  RecentLifeExperienceEvents2["SexualDifficulties"] = "SEXUAL_DIFFICULTIES";
  RecentLifeExperienceEvents2["SpouseChangesOrLosesJob"] = "SPOUSE_CHANGES_OR_LOSES_JOB";
  RecentLifeExperienceEvents2["Trauma"] = "TRAUMA";
  RecentLifeExperienceEvents2["TroubleWithInLaws"] = "TROUBLE_WITH_IN_LAWS";
  RecentLifeExperienceEvents2["UnemploymentLongerThanOneMonth"] = "UNEMPLOYMENT_LONGER_THAN_ONE_MONTH";
  RecentLifeExperienceEvents2["WorkingHoursChanged"] = "WORKING_HOURS_CHANGED";
  return RecentLifeExperienceEvents2;
})(RecentLifeExperienceEvents || {});
var ReferralSourceSearchSortOrder = /* @__PURE__ */ ((ReferralSourceSearchSortOrder2) => {
  ReferralSourceSearchSortOrder2["CreatedAsc"] = "CREATED_ASC";
  ReferralSourceSearchSortOrder2["CreatedDesc"] = "CREATED_DESC";
  ReferralSourceSearchSortOrder2["EmailAsc"] = "EMAIL_ASC";
  ReferralSourceSearchSortOrder2["EmailDesc"] = "EMAIL_DESC";
  ReferralSourceSearchSortOrder2["OrganizationNameAsc"] = "ORGANIZATION_NAME_ASC";
  ReferralSourceSearchSortOrder2["OrganiztionNameDesc"] = "ORGANIZTION_NAME_DESC";
  return ReferralSourceSearchSortOrder2;
})(ReferralSourceSearchSortOrder || {});
var ReferralSourceType = /* @__PURE__ */ ((ReferralSourceType2) => {
  ReferralSourceType2["Individual"] = "INDIVIDUAL";
  ReferralSourceType2["Organization"] = "ORGANIZATION";
  ReferralSourceType2["Self"] = "SELF";
  return ReferralSourceType2;
})(ReferralSourceType || {});
var ReservationTypes = /* @__PURE__ */ ((ReservationTypes2) => {
  ReservationTypes2["ApppointmentAvailability"] = "APPPOINTMENT_AVAILABILITY";
  ReservationTypes2["AssessmentAvailability"] = "ASSESSMENT_AVAILABILITY";
  ReservationTypes2["StandardAvailability"] = "STANDARD_AVAILABILITY";
  return ReservationTypes2;
})(ReservationTypes || {});
var SectionStatus = /* @__PURE__ */ ((SectionStatus2) => {
  SectionStatus2["Completed"] = "COMPLETED";
  SectionStatus2["InProgress"] = "IN_PROGRESS";
  SectionStatus2["NotStarted"] = "NOT_STARTED";
  SectionStatus2["ReadyToStart"] = "READY_TO_START";
  return SectionStatus2;
})(SectionStatus || {});
var SelectRelatedProblemCategory = /* @__PURE__ */ ((SelectRelatedProblemCategory2) => {
  SelectRelatedProblemCategory2["HabitOrSubstance"] = "HABIT_OR_SUBSTANCE";
  SelectRelatedProblemCategory2["MostImportantProblem"] = "MOST_IMPORTANT_PROBLEM";
  return SelectRelatedProblemCategory2;
})(SelectRelatedProblemCategory || {});
var SessionStatus = /* @__PURE__ */ ((SessionStatus2) => {
  SessionStatus2["Abandoned"] = "ABANDONED";
  SessionStatus2["Completed"] = "COMPLETED";
  SessionStatus2["InProgress"] = "IN_PROGRESS";
  SessionStatus2["NotStarted"] = "NOT_STARTED";
  return SessionStatus2;
})(SessionStatus || {});
var SexualOrientation = /* @__PURE__ */ ((SexualOrientation2) => {
  SexualOrientation2["Bisexual"] = "BISEXUAL";
  SexualOrientation2["GayLesbian"] = "GAY_LESBIAN";
  SexualOrientation2["PreferNotToSay"] = "PREFER_NOT_TO_SAY";
  SexualOrientation2["Straight"] = "STRAIGHT";
  return SexualOrientation2;
})(SexualOrientation || {});
var StateOfChange = /* @__PURE__ */ ((StateOfChange2) => {
  StateOfChange2["ActionWillpower"] = "ACTION_WILLPOWER";
  StateOfChange2["Contemplation"] = "CONTEMPLATION";
  StateOfChange2["Maintenance"] = "MAINTENANCE";
  StateOfChange2["PreparationDetermination"] = "PREPARATION_DETERMINATION";
  StateOfChange2["PreContemplation"] = "PRE_CONTEMPLATION";
  StateOfChange2["Relapse"] = "RELAPSE";
  return StateOfChange2;
})(StateOfChange || {});
var StrategyType = /* @__PURE__ */ ((StrategyType2) => {
  StrategyType2["ConnectedWithPeer"] = "CONNECTED_WITH_PEER";
  StrategyType2["CravingTimer"] = "CRAVING_TIMER";
  StrategyType2["EmailPeer"] = "EMAIL_PEER";
  StrategyType2["MindfulBreathing"] = "MINDFUL_BREATHING";
  StrategyType2["MindfulWalking"] = "MINDFUL_WALKING";
  StrategyType2["ThoughtRecord"] = "THOUGHT_RECORD";
  return StrategyType2;
})(StrategyType || {});
var SubstanceList = /* @__PURE__ */ ((SubstanceList2) => {
  SubstanceList2["Alcohol"] = "ALCOHOL";
  SubstanceList2["Cocaine"] = "COCAINE";
  SubstanceList2["Depressants"] = "DEPRESSANTS";
  SubstanceList2["Hallucinogens"] = "HALLUCINOGENS";
  SubstanceList2["Inhalants"] = "INHALANTS";
  SubstanceList2["MarijuanaCannabis"] = "MARIJUANA_CANNABIS";
  SubstanceList2["Methamphetamines"] = "METHAMPHETAMINES";
  SubstanceList2["NicotineTobacco"] = "NICOTINE_TOBACCO";
  SubstanceList2["OpioidsOrPainKillers"] = "OPIOIDS_OR_PAIN_KILLERS";
  SubstanceList2["Other"] = "OTHER";
  SubstanceList2["Stimulants"] = "STIMULANTS";
  return SubstanceList2;
})(SubstanceList || {});
var SupportTeamMemberRole = /* @__PURE__ */ ((SupportTeamMemberRole2) => {
  SupportTeamMemberRole2["CareConcierge"] = "CARE_CONCIERGE";
  SupportTeamMemberRole2["Counselor"] = "COUNSELOR";
  SupportTeamMemberRole2["Group"] = "GROUP";
  SupportTeamMemberRole2["Medical"] = "MEDICAL";
  SupportTeamMemberRole2["PeerSupport"] = "PEER_SUPPORT";
  return SupportTeamMemberRole2;
})(SupportTeamMemberRole || {});
var SupportTeamMemberTimingDayOfWeek = /* @__PURE__ */ ((SupportTeamMemberTimingDayOfWeek2) => {
  SupportTeamMemberTimingDayOfWeek2["Friday"] = "FRIDAY";
  SupportTeamMemberTimingDayOfWeek2["Monday"] = "MONDAY";
  SupportTeamMemberTimingDayOfWeek2["Saturday"] = "SATURDAY";
  SupportTeamMemberTimingDayOfWeek2["Sunday"] = "SUNDAY";
  SupportTeamMemberTimingDayOfWeek2["Thursday"] = "THURSDAY";
  SupportTeamMemberTimingDayOfWeek2["Tuesday"] = "TUESDAY";
  SupportTeamMemberTimingDayOfWeek2["Wednesday"] = "WEDNESDAY";
  return SupportTeamMemberTimingDayOfWeek2;
})(SupportTeamMemberTimingDayOfWeek || {});
var SupportTeamMemberTimingPeriod = /* @__PURE__ */ ((SupportTeamMemberTimingPeriod2) => {
  SupportTeamMemberTimingPeriod2["Day"] = "DAY";
  SupportTeamMemberTimingPeriod2["Hour"] = "HOUR";
  SupportTeamMemberTimingPeriod2["Minute"] = "MINUTE";
  SupportTeamMemberTimingPeriod2["Month"] = "MONTH";
  SupportTeamMemberTimingPeriod2["Second"] = "SECOND";
  SupportTeamMemberTimingPeriod2["Week"] = "WEEK";
  SupportTeamMemberTimingPeriod2["Year"] = "YEAR";
  return SupportTeamMemberTimingPeriod2;
})(SupportTeamMemberTimingPeriod || {});
var TaskPriority = /* @__PURE__ */ ((TaskPriority2) => {
  TaskPriority2["Asap"] = "ASAP";
  TaskPriority2["Routine"] = "ROUTINE";
  TaskPriority2["Stat"] = "STAT";
  TaskPriority2["Urgent"] = "URGENT";
  return TaskPriority2;
})(TaskPriority || {});
var TaskSearchSortOrder = /* @__PURE__ */ ((TaskSearchSortOrder2) => {
  TaskSearchSortOrder2["DueDateAsc"] = "DUE_DATE_ASC";
  TaskSearchSortOrder2["DueDateDesc"] = "DUE_DATE_DESC";
  TaskSearchSortOrder2["TitleAsc"] = "TITLE_ASC";
  TaskSearchSortOrder2["TitleDesc"] = "TITLE_DESC";
  return TaskSearchSortOrder2;
})(TaskSearchSortOrder || {});
var TaskStatus = /* @__PURE__ */ ((TaskStatus2) => {
  TaskStatus2["Accepted"] = "ACCEPTED";
  TaskStatus2["Archive"] = "ARCHIVE";
  TaskStatus2["Cancelled"] = "CANCELLED";
  TaskStatus2["Completed"] = "COMPLETED";
  TaskStatus2["Created"] = "CREATED";
  TaskStatus2["Draft"] = "DRAFT";
  TaskStatus2["EnteredInError"] = "ENTERED_IN_ERROR";
  TaskStatus2["InProgress"] = "IN_PROGRESS";
  TaskStatus2["OnHold"] = "ON_HOLD";
  TaskStatus2["Ready"] = "READY";
  TaskStatus2["Received"] = "RECEIVED";
  TaskStatus2["Rejected"] = "REJECTED";
  TaskStatus2["Requested"] = "REQUESTED";
  TaskStatus2["Stopped"] = "STOPPED";
  return TaskStatus2;
})(TaskStatus || {});
var ThemeSeason = /* @__PURE__ */ ((ThemeSeason2) => {
  ThemeSeason2["Fall"] = "FALL";
  ThemeSeason2["Spring"] = "SPRING";
  ThemeSeason2["Summer"] = "SUMMER";
  ThemeSeason2["Winter"] = "WINTER";
  return ThemeSeason2;
})(ThemeSeason || {});
var ThemeTimeOfDay = /* @__PURE__ */ ((ThemeTimeOfDay2) => {
  ThemeTimeOfDay2["Afternoon"] = "AFTERNOON";
  ThemeTimeOfDay2["Evening"] = "EVENING";
  ThemeTimeOfDay2["Morning"] = "MORNING";
  ThemeTimeOfDay2["Night"] = "NIGHT";
  return ThemeTimeOfDay2;
})(ThemeTimeOfDay || {});
var ThoughtRecordHabitEffect = /* @__PURE__ */ ((ThoughtRecordHabitEffect2) => {
  ThoughtRecordHabitEffect2["DidUse"] = "DID_USE";
  ThoughtRecordHabitEffect2["HadCraving"] = "HAD_CRAVING";
  ThoughtRecordHabitEffect2["NoCravingsOrUse"] = "NO_CRAVINGS_OR_USE";
  return ThoughtRecordHabitEffect2;
})(ThoughtRecordHabitEffect || {});
var TimeOfDay = /* @__PURE__ */ ((TimeOfDay2) => {
  TimeOfDay2["Afternoon"] = "AFTERNOON";
  TimeOfDay2["Evening"] = "EVENING";
  TimeOfDay2["Morning"] = "MORNING";
  TimeOfDay2["Night"] = "NIGHT";
  return TimeOfDay2;
})(TimeOfDay || {});
var UsStates = /* @__PURE__ */ ((UsStates2) => {
  UsStates2["Alabama"] = "ALABAMA";
  UsStates2["Alaska"] = "ALASKA";
  UsStates2["Arizona"] = "ARIZONA";
  UsStates2["Arkansas"] = "ARKANSAS";
  UsStates2["California"] = "CALIFORNIA";
  UsStates2["Colorado"] = "COLORADO";
  UsStates2["Connecticut"] = "CONNECTICUT";
  UsStates2["Delaware"] = "DELAWARE";
  UsStates2["DistrictOfColumbia"] = "DISTRICT_OF_COLUMBIA";
  UsStates2["Florida"] = "FLORIDA";
  UsStates2["Georgia"] = "GEORGIA";
  UsStates2["Hawaii"] = "HAWAII";
  UsStates2["Idaho"] = "IDAHO";
  UsStates2["Illinois"] = "ILLINOIS";
  UsStates2["Indiana"] = "INDIANA";
  UsStates2["Iowa"] = "IOWA";
  UsStates2["Kansas"] = "KANSAS";
  UsStates2["Kentucky"] = "KENTUCKY";
  UsStates2["Louisiana"] = "LOUISIANA";
  UsStates2["Maine"] = "MAINE";
  UsStates2["Maryland"] = "MARYLAND";
  UsStates2["Massachusetts"] = "MASSACHUSETTS";
  UsStates2["Michigan"] = "MICHIGAN";
  UsStates2["Minnesota"] = "MINNESOTA";
  UsStates2["Mississippi"] = "MISSISSIPPI";
  UsStates2["Missouri"] = "MISSOURI";
  UsStates2["Montana"] = "MONTANA";
  UsStates2["Nebraska"] = "NEBRASKA";
  UsStates2["Nevada"] = "NEVADA";
  UsStates2["NewHampshire"] = "NEW_HAMPSHIRE";
  UsStates2["NewJersey"] = "NEW_JERSEY";
  UsStates2["NewMexico"] = "NEW_MEXICO";
  UsStates2["NewYork"] = "NEW_YORK";
  UsStates2["NorthCarolina"] = "NORTH_CAROLINA";
  UsStates2["NorthDakota"] = "NORTH_DAKOTA";
  UsStates2["Ohio"] = "OHIO";
  UsStates2["Oklahoma"] = "OKLAHOMA";
  UsStates2["Oregon"] = "OREGON";
  UsStates2["Pennsylvania"] = "PENNSYLVANIA";
  UsStates2["PuertoRico"] = "PUERTO_RICO";
  UsStates2["RhodeIsland"] = "RHODE_ISLAND";
  UsStates2["SouthCarolina"] = "SOUTH_CAROLINA";
  UsStates2["SouthDakota"] = "SOUTH_DAKOTA";
  UsStates2["Tennessee"] = "TENNESSEE";
  UsStates2["Texas"] = "TEXAS";
  UsStates2["Utah"] = "UTAH";
  UsStates2["Vermont"] = "VERMONT";
  UsStates2["Virginia"] = "VIRGINIA";
  UsStates2["Washington"] = "WASHINGTON";
  UsStates2["WestVirginia"] = "WEST_VIRGINIA";
  UsStates2["Wisconsin"] = "WISCONSIN";
  UsStates2["Wyoming"] = "WYOMING";
  return UsStates2;
})(UsStates || {});
var UserFormType = /* @__PURE__ */ ((UserFormType2) => {
  UserFormType2["Other"] = "OTHER";
  UserFormType2["Signature"] = "SIGNATURE";
  UserFormType2["Upload"] = "UPLOAD";
  return UserFormType2;
})(UserFormType || {});
var UserFormUse = /* @__PURE__ */ ((UserFormUse2) => {
  UserFormUse2["Behavioral"] = "BEHAVIORAL";
  UserFormUse2["Intake"] = "INTAKE";
  UserFormUse2["Medical"] = "MEDICAL";
  UserFormUse2["Other"] = "OTHER";
  return UserFormUse2;
})(UserFormUse || {});
var UserGroupSearchSortOrder = /* @__PURE__ */ ((UserGroupSearchSortOrder2) => {
  UserGroupSearchSortOrder2["CreatedAsc"] = "CREATED_ASC";
  UserGroupSearchSortOrder2["CreatedDesc"] = "CREATED_DESC";
  UserGroupSearchSortOrder2["NameAsc"] = "NAME_ASC";
  UserGroupSearchSortOrder2["NameDesc"] = "NAME_DESC";
  return UserGroupSearchSortOrder2;
})(UserGroupSearchSortOrder || {});
var UserGroupStatus = /* @__PURE__ */ ((UserGroupStatus2) => {
  UserGroupStatus2["Active"] = "ACTIVE";
  UserGroupStatus2["Deleted"] = "DELETED";
  UserGroupStatus2["Inactive"] = "INACTIVE";
  return UserGroupStatus2;
})(UserGroupStatus || {});
var UserLearnMoreInterestedIn = /* @__PURE__ */ ((UserLearnMoreInterestedIn2) => {
  UserLearnMoreInterestedIn2["AReferralPartnership"] = "A_REFERRAL_PARTNERSHIP";
  UserLearnMoreInterestedIn2["HelpForMyself"] = "HELP_FOR_MYSELF";
  UserLearnMoreInterestedIn2["HelpForOurEmployees"] = "HELP_FOR_OUR_EMPLOYEES";
  UserLearnMoreInterestedIn2["HelpForOurPatients"] = "HELP_FOR_OUR_PATIENTS";
  return UserLearnMoreInterestedIn2;
})(UserLearnMoreInterestedIn || {});
var UserOnlineStatus = /* @__PURE__ */ ((UserOnlineStatus2) => {
  UserOnlineStatus2["Away"] = "AWAY";
  UserOnlineStatus2["Offline"] = "OFFLINE";
  UserOnlineStatus2["Online"] = "ONLINE";
  return UserOnlineStatus2;
})(UserOnlineStatus || {});
var UserReferralChecklistType = /* @__PURE__ */ ((UserReferralChecklistType2) => {
  UserReferralChecklistType2["AppInviteSent"] = "APP_INVITE_SENT";
  UserReferralChecklistType2["CheckAndDownloadPdmp"] = "CHECK_AND_DOWNLOAD_PDMP";
  UserReferralChecklistType2["CounselingIntakeCompleted"] = "COUNSELING_INTAKE_COMPLETED";
  UserReferralChecklistType2["CounselingIntakeScheduled"] = "COUNSELING_INTAKE_SCHEDULED";
  UserReferralChecklistType2["FirstAppLogin"] = "FIRST_APP_LOGIN";
  UserReferralChecklistType2["FirstCallAttempt"] = "FIRST_CALL_ATTEMPT";
  UserReferralChecklistType2["InitialScreeningStarted"] = "INITIAL_SCREENING_STARTED";
  UserReferralChecklistType2["InsuranceInvestigationComplete"] = "INSURANCE_INVESTIGATION_COMPLETE";
  UserReferralChecklistType2["IntakeFormsComplete"] = "INTAKE_FORMS_COMPLETE";
  UserReferralChecklistType2["IntakeFormsSent"] = "INTAKE_FORMS_SENT";
  UserReferralChecklistType2["IntakeScreeningCompleted"] = "INTAKE_SCREENING_COMPLETED";
  UserReferralChecklistType2["MedicalIntakeCompleted"] = "MEDICAL_INTAKE_COMPLETED";
  UserReferralChecklistType2["MedicalIntakeScheduled"] = "MEDICAL_INTAKE_SCHEDULED";
  UserReferralChecklistType2["PrereferralReceived"] = "PREREFERRAL_RECEIVED";
  UserReferralChecklistType2["ReferralReceived"] = "REFERRAL_RECEIVED";
  UserReferralChecklistType2["ReviewPaperwork"] = "REVIEW_PAPERWORK";
  UserReferralChecklistType2["RoisComplete"] = "ROIS_COMPLETE";
  UserReferralChecklistType2["RoisSent"] = "ROIS_SENT";
  UserReferralChecklistType2["WillNotConvert"] = "WILL_NOT_CONVERT";
  return UserReferralChecklistType2;
})(UserReferralChecklistType || {});
var UserRoleSearchSortOrder = /* @__PURE__ */ ((UserRoleSearchSortOrder2) => {
  UserRoleSearchSortOrder2["CreatedAsc"] = "CREATED_ASC";
  UserRoleSearchSortOrder2["CreatedDesc"] = "CREATED_DESC";
  UserRoleSearchSortOrder2["NameAsc"] = "NAME_ASC";
  UserRoleSearchSortOrder2["NameDesc"] = "NAME_DESC";
  return UserRoleSearchSortOrder2;
})(UserRoleSearchSortOrder || {});
var UserRoleStatus = /* @__PURE__ */ ((UserRoleStatus2) => {
  UserRoleStatus2["Active"] = "ACTIVE";
  UserRoleStatus2["Deleted"] = "DELETED";
  UserRoleStatus2["Inactive"] = "INACTIVE";
  return UserRoleStatus2;
})(UserRoleStatus || {});
var UserRoleType = /* @__PURE__ */ ((UserRoleType2) => {
  UserRoleType2["Admin"] = "ADMIN";
  UserRoleType2["AiCompanion"] = "AI_COMPANION";
  UserRoleType2["AiSupervisor"] = "AI_SUPERVISOR";
  UserRoleType2["Billing"] = "BILLING";
  UserRoleType2["CareConcierge"] = "CARE_CONCIERGE";
  UserRoleType2["Client"] = "CLIENT";
  UserRoleType2["ClinicalSupervisor"] = "CLINICAL_SUPERVISOR";
  UserRoleType2["ContentManager"] = "CONTENT_MANAGER";
  UserRoleType2["Counselor"] = "COUNSELOR";
  UserRoleType2["Developer"] = "DEVELOPER";
  UserRoleType2["Group"] = "GROUP";
  UserRoleType2["IgnoreReports"] = "IGNORE_REPORTS";
  UserRoleType2["Maintainer"] = "MAINTAINER";
  UserRoleType2["Medical"] = "MEDICAL";
  UserRoleType2["NeedSupervisor"] = "NEED_SUPERVISOR";
  UserRoleType2["OnCall"] = "ON_CALL";
  UserRoleType2["PeerSupport"] = "PEER_SUPPORT";
  return UserRoleType2;
})(UserRoleType || {});
var UserSearchSortOrder = /* @__PURE__ */ ((UserSearchSortOrder2) => {
  UserSearchSortOrder2["CreatedAsc"] = "CREATED_ASC";
  UserSearchSortOrder2["CreatedDesc"] = "CREATED_DESC";
  UserSearchSortOrder2["EmailAsc"] = "EMAIL_ASC";
  UserSearchSortOrder2["EmailDesc"] = "EMAIL_DESC";
  UserSearchSortOrder2["FirstNameAsc"] = "FIRST_NAME_ASC";
  UserSearchSortOrder2["FirstNameDesc"] = "FIRST_NAME_DESC";
  UserSearchSortOrder2["HasSubmittedPriorityOnboardingEmailAsc"] = "HAS_SUBMITTED_PRIORITY_ONBOARDING_EMAIL_ASC";
  UserSearchSortOrder2["HasSubmittedPriorityOnboardingEmailDesc"] = "HAS_SUBMITTED_PRIORITY_ONBOARDING_EMAIL_DESC";
  UserSearchSortOrder2["LastNameAsc"] = "LAST_NAME_ASC";
  UserSearchSortOrder2["LastNameDesc"] = "LAST_NAME_DESC";
  UserSearchSortOrder2["LastSeenAsc"] = "LAST_SEEN_ASC";
  UserSearchSortOrder2["LastSeenDesc"] = "LAST_SEEN_DESC";
  UserSearchSortOrder2["NameAsc"] = "NAME_ASC";
  UserSearchSortOrder2["NameDesc"] = "NAME_DESC";
  UserSearchSortOrder2["Noshowrateasc"] = "NOSHOWRATEASC";
  UserSearchSortOrder2["Noshowratedesc"] = "NOSHOWRATEDESC";
  UserSearchSortOrder2["ReferralSourceAsc"] = "REFERRAL_SOURCE_ASC";
  UserSearchSortOrder2["ReferralSourceDesc"] = "REFERRAL_SOURCE_DESC";
  UserSearchSortOrder2["StatusAsc"] = "STATUS_ASC";
  UserSearchSortOrder2["StatusDesc"] = "STATUS_DESC";
  UserSearchSortOrder2["TagsAsc"] = "TAGS_ASC";
  UserSearchSortOrder2["TagsDesc"] = "TAGS_DESC";
  return UserSearchSortOrder2;
})(UserSearchSortOrder || {});
var UserStatus = /* @__PURE__ */ ((UserStatus2) => {
  UserStatus2["Active"] = "ACTIVE";
  UserStatus2["Discharged"] = "DISCHARGED";
  UserStatus2["Duplicate"] = "DUPLICATE";
  UserStatus2["Inactive"] = "INACTIVE";
  UserStatus2["Invited"] = "INVITED";
  UserStatus2["Lead"] = "LEAD";
  UserStatus2["NotAFit"] = "NOT_A_FIT";
  UserStatus2["Onboarding"] = "ONBOARDING";
  UserStatus2["PendingDelete"] = "PENDING_DELETE";
  UserStatus2["Referral"] = "REFERRAL";
  UserStatus2["Stale"] = "STALE";
  UserStatus2["Suspended"] = "SUSPENDED";
  return UserStatus2;
})(UserStatus || {});
var UserType = /* @__PURE__ */ ((UserType2) => {
  UserType2["Admin"] = "ADMIN";
  UserType2["Client"] = "CLIENT";
  UserType2["Therapist"] = "THERAPIST";
  return UserType2;
})(UserType || {});
var VideoCollectionNotificationTimePreference = /* @__PURE__ */ ((VideoCollectionNotificationTimePreference2) => {
  VideoCollectionNotificationTimePreference2["ElevenAm"] = "ELEVEN_AM";
  VideoCollectionNotificationTimePreference2["NinePm"] = "NINE_PM";
  VideoCollectionNotificationTimePreference2["None"] = "NONE";
  VideoCollectionNotificationTimePreference2["Released"] = "RELEASED";
  VideoCollectionNotificationTimePreference2["SixPm"] = "SIX_PM";
  VideoCollectionNotificationTimePreference2["ThreePm"] = "THREE_PM";
  return VideoCollectionNotificationTimePreference2;
})(VideoCollectionNotificationTimePreference || {});
var VideoCollectionSearchSortOrder = /* @__PURE__ */ ((VideoCollectionSearchSortOrder2) => {
  VideoCollectionSearchSortOrder2["CreatedAsc"] = "CREATED_ASC";
  VideoCollectionSearchSortOrder2["CreatedDesc"] = "CREATED_DESC";
  VideoCollectionSearchSortOrder2["TitleAsc"] = "TITLE_ASC";
  VideoCollectionSearchSortOrder2["TitleDesc"] = "TITLE_DESC";
  return VideoCollectionSearchSortOrder2;
})(VideoCollectionSearchSortOrder || {});
var VideoCollectionStatus = /* @__PURE__ */ ((VideoCollectionStatus2) => {
  VideoCollectionStatus2["Archived"] = "ARCHIVED";
  VideoCollectionStatus2["Draft"] = "DRAFT";
  VideoCollectionStatus2["Published"] = "PUBLISHED";
  return VideoCollectionStatus2;
})(VideoCollectionStatus || {});
var VideoMediaType = /* @__PURE__ */ ((VideoMediaType2) => {
  VideoMediaType2["Audio"] = "audio";
  VideoMediaType2["Videos"] = "videos";
  return VideoMediaType2;
})(VideoMediaType || {});
var VideoSearchSortOrder = /* @__PURE__ */ ((VideoSearchSortOrder2) => {
  VideoSearchSortOrder2["CreatedAsc"] = "CREATED_ASC";
  VideoSearchSortOrder2["CreatedDesc"] = "CREATED_DESC";
  VideoSearchSortOrder2["TitleAsc"] = "TITLE_ASC";
  VideoSearchSortOrder2["TitleDesc"] = "TITLE_DESC";
  return VideoSearchSortOrder2;
})(VideoSearchSortOrder || {});
var VideoStatus = /* @__PURE__ */ ((VideoStatus2) => {
  VideoStatus2["Archived"] = "ARCHIVED";
  VideoStatus2["Draft"] = "DRAFT";
  VideoStatus2["Published"] = "PUBLISHED";
  VideoStatus2["ScheduledWeekly"] = "SCHEDULED_WEEKLY";
  return VideoStatus2;
})(VideoStatus || {});
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  ActivityFeedContentType,
  ActivityFeedSearchSortOrder,
  ActivityFeelings,
  AddressType,
  AddressUse,
  AiConversationSearchSortOrder,
  AiMessageSearchSortOrder,
  AiSettingType,
  AppointmentTypes,
  AreaOfConcernType,
  AreasOfLife,
  AreasOfLifeHabitImpact,
  BirthSex,
  CalendarEventStatuses,
  CalendarEventTypes,
  CheckinType,
  CommunityGroupVisibility,
  CommunityStatus,
  CommunityUserAvatarBackgroundColor,
  CommunityUserAvatarSvgIcon,
  CommunityUserAvatarType,
  ConversationReviewStatus,
  DoesUseSubstanceMoreThanUsuallyFrequencyPattern,
  DownloadEbookSupportedCondition,
  DrawbackOrConcernCategory,
  EducationLevel,
  EmailType,
  EmojiReaction,
  EmploymentDurationType,
  EmploymentStatus,
  EncounterNotesAiStatus,
  EncounterNotesClinicianStatus,
  EncounterNotesReasonForMissedAppointment,
  EncounterNotesSearchSortOrder,
  EncounterNotesStatus,
  EncounterNotesSupervisorStatus,
  EntryType,
  FamilyRelationshipType,
  FeelingsAfterTechnique,
  FileAttachmentStatus,
  FilesSearchSortOrder,
  ForYouCardType,
  FormStatus,
  FriendStatus,
  GenderIdentity,
  HabitAndSubstanceList,
  HabitBenefitAssessment,
  HabitCategory,
  HabitOrSubstanceUseFrequencyPattern,
  HabitOrSubstanceUseTimeOfDay,
  HabitsList,
  HowMuchConcern,
  HowMuchTimeToInvestToBetterManageUnwantedHabits,
  IntakeStatus,
  InterferenceFrequencyInDailyLife,
  InviteStatus,
  InvitedToDownloadMethod,
  InvitedToDownloadStatus,
  LifeExperienceCategory,
  MaritalStatus,
  MediaMessageType,
  MilitaryBranch,
  Mood,
  NoteContentType,
  NoteStatus,
  NoteType,
  PastLifeExperienceEvents,
  PastOrRecentLifeExperienceCategory,
  PostInteractionType,
  PostMediaAttachmentType,
  PostStatus,
  PreferenceTimeOfDay,
  ProblemOrConcernCategory,
  Pronouns,
  ProspectStatus,
  Race,
  ReasonsOrBenefitsForUsingCategory,
  RecentLifeExperienceEvents,
  ReferralSourceSearchSortOrder,
  ReferralSourceType,
  ReservationTypes,
  SectionStatus,
  SelectRelatedProblemCategory,
  SessionStatus,
  SexualOrientation,
  StateOfChange,
  StrategyType,
  SubstanceList,
  SupportTeamMemberRole,
  SupportTeamMemberTimingDayOfWeek,
  SupportTeamMemberTimingPeriod,
  TaskPriority,
  TaskSearchSortOrder,
  TaskStatus,
  ThemeSeason,
  ThemeTimeOfDay,
  ThoughtRecordHabitEffect,
  TimeOfDay,
  UsStates,
  UserFormType,
  UserFormUse,
  UserGroupSearchSortOrder,
  UserGroupStatus,
  UserLearnMoreInterestedIn,
  UserOnlineStatus,
  UserReferralChecklistType,
  UserRoleSearchSortOrder,
  UserRoleStatus,
  UserRoleType,
  UserSearchSortOrder,
  UserStatus,
  UserType,
  VideoCollectionNotificationTimePreference,
  VideoCollectionSearchSortOrder,
  VideoCollectionStatus,
  VideoMediaType,
  VideoSearchSortOrder,
  VideoStatus
});
