var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"bc36790569f4eec6ffd8abc9dd640d17e227f551"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

const currentStage = process.env.STAGE || process.env.NEXT_PUBLIC_STAGE;

function getSentryEnvironment() {
  if (currentStage === "dev" || currentStage === "beta") {
    return "beta";
  }
  return currentStage;
}

Sentry.init({
  dsn: "https://dde60eac7621840b9fcf0bc44dc15324@o4506021065981952.ingest.us.sentry.io/4507057204559872",
  // Replay may only be enabled for the client-side
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here...
      maskAllText: true,
      blockAllMedia: true,
      maskAllInputs: true,
    }),
  ],

  enabled: process.env.NODE_ENV === "production",

  environment: getSentryEnvironment(),
  release:
    process.env.RELEASE_VERSION ||
    process.env.NEXT_PUBLIC_RELEASE_VERSION ||
    "0.0.0",

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.05,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // ...

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
